import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Dropdown, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AddEmployeeAvailability from "../Modal/AddEmployeeAvailability";
import EditUser from "../Modal/EditUser";
import DeleteUser from "../Modal/DeleteUser";
import ReactTost from "../../ReactTost";
import { formatDate } from "date-fns";
import DeleteAvailability from "../Modal/DeleteAvailability";
import EditAvailability from "../Modal/EditAvailability";
import moment from "moment";
import CloneAvailability from "../Modal/CloneAvailability";
const UserList = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const API_VERSION = process.env.REACT_APP_API_VERSION;
   const[cloneData,setCloneData]=useState(false);
   const[cloneId,setCloneId]=useState();
    const [type, setType] = useState("");
    const [message, setMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [addModalShow, setAddModalShow] = useState(false);
    const [editModalShow, setEditModelShow] = useState(false);
    const [deleteModelShow, setDeleteModelShow] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [editId, setEditId] = useState();
    const [userDelete, setUserDelete] = useState(true);
    const [deletedId, setDeletedId] = useState();
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const columns = [
        {
            name: "Date",
            selector: "date",
           //  cell: (row) => formatDate(new Date(row.date), "dd/MM/yyyy")
        },
        {
            name: "Start time",
            selector: "startTime",
            cell: (row) => row.startTime.join(", ")
          
           
        },
        {
            name: "End time",
            selector: "endTime",
            cell: (row) => row.endTime.join(", ")
        },
        {
            name: "Employee",
            selector: "employee",
            cell: (row) => `${row.employeeDetails.firstName} ${row.employeeDetails.lastName}`
        },
      
        {
            name: "Request Grade",
            selector: "grade",
        },
        {
            name: "Priority",
            selector: "priority",
        },
        {
            name: "Status",
            selector: "status",
        },

        {
            name: "Action",
            cell: (row) => {
                return (
                    <>
                        <p className="d-flex flex-wrap gap-2">



                            <Link
                                className="button"
                                onClick={() => {
                                    setEditModelShow(true);
                                    setEditId(row._id);
                                }}
                            >
                                <i className="fa-solid fa-pen-to-square"></i>

                            </Link>

                            <Link
                                className="button"
                                onClick={() => {
                                    setDeleteModelShow(true);
                                    setDeletedId(row._id);
                                }}
                            >
                                <i className="fa-solid fa-trash-can"></i>

                            </Link>

                            <Link
                                className="button"
                                onClick={() => {
                                    setCloneData(true);
                                    setCloneId(row._id);
                                }}
                            >
                                <i className="fa fa-clone"></i>

                            </Link>

                        </p>
                    </>
                );
            },
        },
    ];



    function fetchUsers() {
        let setUserUrl = `${API_URL}/${API_VERSION}/availability?&page=${page}&limit=${countPerPage}`;
        if (searchValue) {
            setUserUrl += `&searchValue=${searchValue}`;
        }
        axios
            .get(setUserUrl, {
                headers: {
                    accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((response) => {
            
                setUsersList(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        fetchUsers();
    }, [API_URL, API_VERSION, page]);

    return (
        <>
            <ReactTost
                onHide={() => {
                    setShowAlert(false);
                    setType("");
                    setMessage("");
                }}
                alertClass={type}
                alertMessage={message}
                alertDo={showAlert}
            />
            <section className="user_main">
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header as="h5">
                                    <div className="listing_head">
                                        <div className="head_title">
                                            <h3 className="mb-0">Employee availability</h3>
                                        </div>
                                        <div className="seller_user_head">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={searchValue}
                                                onChange={(e) => setSearchValue(e.target.value)}
                                            />
                                            <button
                                                className="button"
                                                onClick={() => fetchUsers()}
                                            >
                                                Search
                                            </button>
                                            <button
                                                className="button"
                                                onClick={() => setAddModalShow(true)}
                                            >
                                                <i className="fa-solid fa-circle-plus"></i>{" "}
                                                Add 
                                            </button>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="user_table">
                                        <DataTable
                                            allowOverflow
                                            columns={columns}
                                            data={usersList.docs}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={usersList.totalDocs}
                                            paginationPerPage={countPerPage}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true,
                                            }}
                                            onChangePage={(page) => setPage(page)}
                                            noDataComponent={null}
                                        />
                                           {(!usersList.docs || usersList.docs.length === 0) && (
                                            <table className="user_table">
                                                <thead>
                                                    <tr>
                                                        {columns.map((col) => (
                                                            <th key={col.selector}>{col.name}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={columns.length} className="text-center">
                                                            No record available
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
            {addModalShow && (
                <AddEmployeeAvailability
                    show={addModalShow}
                    onHide={() => {
                        fetchUsers();
                        setAddModalShow(false);
                    }}
                />
            )}

            {editModalShow && (
                <EditAvailability
                    show={editModalShow}
                    id={editId}
                    onHide={() => {
                        fetchUsers();
                        setEditModelShow(false);
                    }}
                />
            )}

{cloneData && (
                <CloneAvailability
                    show={cloneData}
                    id={cloneId}
                    onHide={() => {
                        fetchUsers();
                        setCloneData(false);
                    }}
                />
            )}

            {deleteModelShow && (
                <DeleteAvailability
                    show={deleteModelShow}
                    id={deletedId}
                    onHide={() => {
                        fetchUsers();
                        setDeleteModelShow(false);
                    }}
                />
            )}
        </>
    );
};

export default UserList;
