import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import { useForm } from "react-hook-form";
import axios from "axios";
import ReactTost from "../../ReactTost";

const AddUser = (props) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  
  const addUser = async (data) => {
    await axios
      .post(
        `${API_URL}/${API_VERSION}/employees`,
        {
          // data
          firstName:data.firstName,
          lastName:data.lastName,
        
         // manager:localStorage.getItem("id"),
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setShowAlert(true);
        setType("Success");
        setMessage("Employee created.");
        setTimeout(() => {
          props.onHide();
          reset();
        }, 1500);
      })
      .catch((error) => {
        console.error("Error sending data:", error);
        setType("Danger");
        setMessage("Something went to wrong.");
        setShowAlert(true);
      });
  };

  

  const handleClose = () => {
    reset();
    props.onHide();
  };


  return (
    <>
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered onHide={handleClose}
      >
        <form onSubmit={handleSubmit(addUser)}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Employee
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group mb-3">
              <label className="">First name</label>
              <input
                className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
                type="text"
                name="firstName"
                id="firstName"
                placeholder=""
                {...register("firstName", { required: "First name is required." })}
              />
              {errors.firstName && (
                <div className="invalid-feedback">{errors.firstName.message}</div>
              )}
            </div>
            <div className="form-group mb-3">
              <label className="">Last name</label>
              <input
                className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
                type="text"
                name="lastName"
                id="lastName"
                placeholder=""
                {...register("lastName", { required: "Last name is required."})}
              />
              {errors.lastName && (
                <div className="invalid-feedback">{errors.lastName.message}</div>
              )}
            </div>
           
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="button">
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddUser;
