import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "user",
  initialState: {
    userData: localStorage.getItem("isLogin")
      ? localStorage.getItem("isLogin")
      : false,
    IsUserLevel: localStorage.getItem("isRole")
      ? localStorage.getItem("isRole")
      : "",
  },
  reducers: {
    logout: (state) => {
      state.userData = false;
      state.IsUserLevel = "";
    },
    login: (state, action) => {
      state.userData += action.payload.userData;
      state.IsUserLevel += action.payload.IsUserLevel;
    },
  },
});

export const { logout, login } = counterSlice.actions;

export default counterSlice.reducer;
