
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import ReactTost from "../../ReactTost";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { format, set } from "date-fns";
import 'rc-time-picker/assets/index.css';
import TimePicker from "rc-time-picker";
import moment from "moment";
const AddUser = (props) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [startTimeError, setStartTimeError] = useState(false);
  const [endTimeError, setEndTimeError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [employeeError, setEmployeeError] = useState(false);
  const [priorityError, setPriorityError] = useState(false);
  const [gradeErr, setGradeErr] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue
  } = useForm();

  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [type, setType] = useState("");
  const [userList, setUsersList] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [startTimeFields, setStartTimeFields] = useState([""]); // State for start times
  const [endTimeFields, setEndTimeFields] = useState([""]); // State for end times

  function fetchUsers() {
    let setUserUrl = `${API_URL}/${API_VERSION}/employees/getEmployees`;

    axios
      .get(setUserUrl, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setUsersList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const addUser = async (data) => {
    // Prepare the data to send, assuming there's only one start and end time
    // console.log(data);
    let hasError = false;
    if (!data.date) {
      setDateError(true);
      hasError = true;
      //return;
    }
    else {
      setDateError(false);
    }
    if (startTimeFields.length === 0 || startTimeFields.includes('')) {
      setStartTimeError(true);
      hasError = true;
      //return;
    }
    if (endTimeFields.length === 0 || endTimeFields.includes('')) {
      setEndTimeError(true);
      hasError = true;
      //return;
    }
    if (!data.employee) {
      setEmployeeError(true);
      hasError = true;
      //return;
    }
    if (!data.grade) {
      setGradeErr(true);
      hasError = true;
      //return;
    }
    if (!data.priority) {
      setPriorityError(true);
      hasError = true;
      //return;
    }
    if (hasError) {
      return;
    }
    const formattedData = {
      date: format(data.date, "dd/MM/yyyy"),
      startTime: startTimeFields,
      endTime: endTimeFields,
      employee: data.employee,
      grade: data.grade,
      priority: data.priority,
    };


    await axios
      .post(`${API_URL}/${API_VERSION}/availability`, formattedData, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setShowAlert(true);
        setType("Success");
        setMessage("Employee availability added.");
        setTimeout(() => {
          props.onHide();
          reset();
        }, 1500);
      })
      .catch((error) => {
        setType("Danger")
        setMessage("Something went wrong.");
        setShowAlert(true);
      });
  };

  const handleClose = () => {
    reset();
    props.onHide();
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDateError(false);
    setValue('date', date);
    setShowDatePicker(false);
  };

  // Function to add a new start time input
  const addStartTimeField = () => {
    setStartTimeFields([...startTimeFields, ""]);
  };

  // Function to remove a start time input
  const removeStartTimeField = (index) => {
    const newStartTimes = startTimeFields.filter((_, i) => i !== index);
    setStartTimeFields(newStartTimes);
  };

  // Function to add a new end time input
  const addEndTimeField = () => {
    setEndTimeFields([...endTimeFields, ""]);
  };

  // Function to remove an end time input
  const removeEndTimeField = (index) => {
    const newEndTimes = endTimeFields.filter((_, i) => i !== index);
    setEndTimeFields(newEndTimes);
  };

  return (
    <>
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        onHide={handleClose}
      >
        <form onSubmit={handleSubmit(addUser)}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Employee availability
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="form-group mb-3">
              <label>Date</label><br />
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                minDate={new Date()}
                dateFormat="dd/MM/yyyy"

                className={`form-control `}
                placeholderText="Select a date"
                customInput={
                  <input
                    style={{ width: "100%" }}
                    readOnly
                  />
                }
              />
              {dateError ? <div className="text-danger">Please select a date.</div> : ""}
            </div>


            <div className="form-group mb-3">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <label>Start Time</label>
              </div>
              {startTimeFields.map((startTime, index) => (
                <div className="d-flex mb-2" key={index}>

                  <TimePicker

                    value={startTime ? moment(startTime, 'HH:mm') : null} // Convert string to moment object
                    showSecond={false}
                    onChange={(time) => {
                      const newStartTimes = [...startTimeFields];
                      newStartTimes[index] = time ? time.format('HH:mm') : ''; // Update with formatted time string
                      setStartTimeFields(newStartTimes);
                      setStartTimeError(false);
                    }}
                  />

                  <button
                    type="button"
                    className="btn btn-danger ms-2 h-2"
                    onClick={() => removeStartTimeField(index)}
                  >
                    -
                  </button>

                </div>
              ))}
              {startTimeError && (
                <div className="text-danger mb-2">Start time is required</div>
              )}
              <div className="d-flex align-items-center mb-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={addStartTimeField}
                >
                  + Add more
                </button>
              </div>
            </div>

            {/* <div className="form-group mb-3">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <label>Start Time</label>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={addStartTimeField}
                                >
                                    + Add more start time
                                </button>
                            </div>

                            {startTimeFields.map((startTime, index) => (
                                <div className="d-flex mb-2" key={index}>
                                    <TimePicker
                                        value={startTime} 
                                         // Assuming startTime is a valid moment object or time string
                                        style={{ width: "100px" }}
                                        showSecond={false}
                                        onChange={(value) => {
                                            const newStartTimes = [...startTimeFields];
                                            newStartTimes[index] = value;  // TimePicker gives you the selected value directly, no 'target.value'
                                            setStartTimeFields(newStartTimes);
                                            setStartTimeError(false);
                                        }}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-danger ms-2"
                                        onClick={() => removeStartTimeField(index)}
                                    >
                                        -
                                    </button>
                                    {errors.startTime?.[index] && <div className="text-danger">{errors.startTime[index]}</div>}
                                </div>
                            ))}

                        </div>*/}

            <div className="form-group mb-3">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <label>End Time</label>
              </div>
              {endTimeFields.map((endTime, index) => (
                <div className="d-flex mb-2" key={index}>
                  <div>
                    <TimePicker
                      value={endTime ? moment(endTime, 'HH:mm') : null} // Convert string to moment object
                      showSecond={false}
                      onChange={(time) => {
                        const newEndTimes = [...endTimeFields];
                        newEndTimes[index] = time ? time.format('HH:mm') : ''; // Update with formatted time string
                        setEndTimeFields(newEndTimes);
                        setEndTimeError(false);
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-danger ms-2"
                    onClick={() => removeEndTimeField(index)}
                  >
                    -
                  </button>
                </div>
              ))}
              {endTimeError && (
                <div className="text-danger mb-2">End time is required</div>
              )}
              <div className="d-flex align-items-center mb-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={addEndTimeField}
                >
                  + Add more
                </button>
              </div>
            </div>

            {/* Employee Select Field */}
            {/* <div className="form-group mb-3">
              <label>Employee</label>
              <select
                className={`form-control `}
                name="employee"
                {...register("employee")}
                onChange={() => setEmployeeError(false)}
                style={{ 
                  maxHeight: '150px',
                  overflowY: 'auto',
                 // scrollbarWidth: 'thin',
                 // scrollbarColor: '#6c757d #f8f9fa'
              }}
              >
                <option value="">Select an employee</option>
                {userList.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.firstName} {user.lastName}
                  </option>
                ))}
              </select>
              {employeeError && <div className="text-danger">Employee is required</div>}
            </div> */}
          <div className="form-group mb-3">
    <label>Employee</label>
 
        <select
            className="form-control"
            name="employee"
            {...register("employee")}
            onChange={() => setEmployeeError(false)}
           // size={10}
         
        >
            <option value="">Select an employee</option>
            {userList.map((user) => (
                <option 
                    key={user.id} 
                    value={user.id}
                    style={{padding: '8px'}}
                >
                    {user.firstName} {user.lastName}
                </option>
            ))}
        </select>
    </div>
    {employeeError && <div className="text-danger">Employee is required</div>}



            {/* Grade Field */}
            <div className="form-group mb-3">
              <label>Request Grade</label>
              <select
                className={`form-control`}
                name="grade"
                onChange={(e) => setGradeErr(false)}
                {...register("grade")}
              >
                <option value="">Select a request grade</option>
                <option value="Band 3 MHSW">Band 3 MHSW</option>
               
                <option value="Band 5 RN">Band 5 RN</option>
                <option value="Band 5 RMN">Band 5 RMN</option>
              </select>
              {gradeErr && <div className="text-danger">Grade is required</div>}
            </div>

            {/* Priority Field */}
            <div className="form-group mb-3">
              <label>Priority</label>
              <input
                className={`form-control`}
                type="number"
                onChange={(e) => setPriorityError(false)}
                {...register("priority")}
              />
              {priorityError && <div className="text-danger">Priority is required</div>}
            </div>

          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn btn-primary">Submit</button>
            <button onClick={handleClose} className="btn btn-secondary">Close</button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddUser;
