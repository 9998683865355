import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AddEmployeeAvailability from "../Modal/AddEmployeeAvailability";
import ReactTost from "../../ReactTost";
import DeleteAvailability from "../Modal/DeleteAvailability";
import EditAvailability from "../Modal/EditAvailability";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import ShowMeesage from "../Modal/ShowMessage";
const BookingLogs = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const API_VERSION = process.env.REACT_APP_API_VERSION;

    const [type, setType] = useState("");
    const [message, setMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [addModalShow, setAddModalShow] = useState(false);
    const [showMessage,setShowMessage]=useState(false);
    const [editModalShow, setEditModelShow] = useState(false);
    const [deleteModelShow, setDeleteModelShow] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [editId, setEditId] = useState();
    const [deletedId, setDeletedId] = useState();
    const [page, setPage] = useState(1);
    const countPerPage = 10;

    const columns = [
        {
            name: "Date",
            selector: "availability_date",
            cell: (row) => {
                if (!row.availability_date) return '';
                
                // Check if date is already in dd/MM/yyyy format
                const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/;
                if (dateFormatRegex.test(row.availability_date)) {
                    return row.availability_date;
                }
                
                return format(new Date(row.availability_date), "dd/MM/yyyy");
            }
        },
        
        {
            name: "Employee",
            selector: "employeeDetails",
            cell: (row) => row.employeeDetails ? `${row.employeeDetails.firstName} ${row.employeeDetails.lastName}` :'',
        },
        {
            name: "Job Id",
            selector: "jobId",
        },
        {
            name: "Start time",
            selector: "startTime",
        },
        {
            name: "End time",
            selector: "endTime",
        },
        {
            name: "Request Grade",
            selector: "grade",
        },
       
        {
            name: "Attempt time",
            selector: "attempt_time",
            cell: (row) => {
                if (!row.attempt_time) return "NA";
                return format(
                    new Date(row.attempt_time).toLocaleString("en-US", {
                        timeZone: "Europe/London"
                    }), 
                    "dd/MM/yyyy HH:mm"
                );
            }
        },
        
        
        
        {
            name: "Status",
            selector: "booking_status",
        },
        {
            name: "Message",
            selector: "booking_message",
            cell: (row) => {
                return (
                    <>
                        <p className="d-flex flex-wrap gap-2">



                            <Link
                                className="button"
                                onClick={() => {
                                    setShowMessage(true);
                                    setMessage(row.booking_message);
                                }}
                            >
                                <i className="fa fa-eye"></i>

                            </Link>

                          
                        </p>
                    </>
                );
            },
        },
    ];

    const fetchUsers = () => {
        let setUserUrl = `${API_URL}/${API_VERSION}/book-employee?page=${page}&limit=${countPerPage}`;
        if (searchValue) {
            setUserUrl += `&searchValue=${searchValue}`;
        }
        axios
            .get(setUserUrl, {
                headers: {
                    accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((response) => {
               console.log("response", response.data);
                setUsersList(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchUsers();
    }, [API_URL, API_VERSION, page]);

    return (
        <>
            <ReactTost
                onHide={() => {
                    setShowAlert(false);
                    setType("");
                    setMessage("");
                }}
                alertClass={type}
                alertMessage={message}
                alertDo={showAlert}
            />
            <section className="user_main">
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header as="h5">
                                    <div className="listing_head">
                                        <div className="head_title">
                                            <h3 className="mb-0">Booking logs</h3>
                                        </div>
                                        <div className="seller_user_head">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={searchValue}
                                                onChange={(e) => setSearchValue(e.target.value)}
                                            />
                                            <button className="button" onClick={fetchUsers}>
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="user_table">
                                        <DataTable
                                            allowOverflow
                                            columns={columns}
                                            data={usersList.docs || []} 
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={usersList.totalDocs || 0} 
                                            paginationPerPage={countPerPage}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true,
                                            }}
                                            onChangePage={(page) => setPage(page)}
                                            noDataComponent={null} // Disable the default no data component
                                        />
                                        {/* Custom rendering of headers and no record message if there's no data */}
                                        {(!usersList.docs || usersList.docs.length === 0) && (
                                            <table className="user_table">
                                                <thead>
                                                    <tr>
                                                        {columns.map((col) => (
                                                            <th key={col.selector}>{col.name}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={columns.length} className="text-center">
                                                            No record available
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
            {addModalShow && (
                <AddEmployeeAvailability
                    show={addModalShow}
                    onHide={() => {
                        fetchUsers();
                        setAddModalShow(false);
                    }}
                />
            )}
            {showMessage && (
                <ShowMeesage
                    show={showMessage}
                    message={message}
                    onHide={() => {
                       // fetchUsers();
                        setShowMessage(false);
                    }}
                />
            )}
            {deleteModelShow && (
                <DeleteAvailability
                    show={deleteModelShow}
                    id={deletedId}
                    onHide={() => {
                        fetchUsers();
                        setDeleteModelShow(false);
                    }}
                />
            )}
        </>
    );
};

export default BookingLogs;
