import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ReactTost from "../../ReactTost";


const DeleteAvailability = (props) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;

  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);


  function userDelete(id) {
    if (id) {
      axios
        .delete(`${API_URL}/${API_VERSION}/availability/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setType("Success");
          setMessage("Availability deleted.");
          setShowAlert(true);setTimeout(() => {
            props.onHide();
          }, 500);
        })
        .catch((error) => {
          setType("Danger");
          setMessage("Something went to wrong");
          setShowAlert(true);
        });
    }
  }

  return (
    <>
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />

      <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Delete availability</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="button" onClick={props.onHide}>
            Cancel
          </button>
          <button className="button color_orange" onClick={() => userDelete(props.id)}>Delete</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteAvailability;
