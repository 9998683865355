
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import ReactTost from "../../ReactTost";
import  DatePicker  from "react-datepicker";
import  TimePicker  from 'rc-time-picker'; // Ensure you have rc-time-picker imported
import moment from 'moment'; // Import moment for handling time
import 'rc-time-picker/assets/index.css'; // Import styles for rc-time-picker
import { format } from "date-fns";

const EditUser = (props) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const API_VERSION = process.env.REACT_APP_API_VERSION;

    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
        setValue,
    } = useForm();
    const [startTimeError, setStartTimeError] = useState(false);
    const [endTimeError, setEndTimeError] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [employeeError, setEmployeeError] = useState(false);
    const [type, setType] = useState("");
    const [priorityError, setPriorityError] = useState(false);
    const [gradeErr, setGradeErr] = useState(false);
    const [message, setMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [userList, setUsersList] = useState([]);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [startTimeFields, setStartTimeFields] = useState([""]); // State for start times
    const [endTimeFields, setEndTimeFields] = useState([""]); // State for end times
    const [selectedEmployee, setSelectedEmployee] = useState("");
    function fetchUsers() {
        let setUserUrl = `${API_URL}/${API_VERSION}/employees/getEmployees`;;

        axios
            .get(setUserUrl, {
                headers: {
                    accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((response) => {
                setUsersList(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const fetchUserDetails = async () => {
        try {
            const response = await axios.get(`${API_URL}/${API_VERSION}/availability/${props.id}`, {
                headers: {
                    accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            const userData = response.data; // Assuming the response structure contains the user's data
            setSelectedDate(userData.date);
            setValue('date', userData.date);
            const employeeData = response.data.employee;
            setSelectedEmployee(employeeData.id); // Set employee ID in state
            setValue("employee", employeeData.id); // Set form employee value
            setValue('grade', userData.grade);
            setValue('priority', userData.priority);
            setStartTimeFields(userData.startTime || [""]);
            setEndTimeFields(userData.endTime || [""]);
        } catch (error) {
            console.log(error);
        }
    };

    const updateUser = async (data) => {
        let hasError = false;
        if (!selectedDate) {
            setDateError(true);
            hasError = true;
            //return;
        }
       
        if (startTimeFields.length === 0 || startTimeFields.includes('')) {
            setStartTimeError(true);
            hasError = true;
            //return;
        }
        if (endTimeFields.length === 0 || endTimeFields.includes('')) {
            setEndTimeError(true);
            hasError = true;
            //return;
        }
        if (!data.employee) {
            setEmployeeError(true);
            hasError = true;
            //return;
        }
        if (!data.grade) {
            setGradeErr(true);
            hasError = true;
            //return;
        }
        if (!data.priority) {
            setPriorityError(true);
            hasError = true;
            //return;
        }
        if (hasError) {
            return;
        }
        let dateToSend = selectedDate;
        if (typeof selectedDate === 'object' && selectedDate instanceof Date) {
            // If it's a Date object (user edited), format it
            dateToSend = format(selectedDate, "dd/MM/yyyy");
        }
        const formattedData = {
            date: dateToSend,
            startTime: startTimeFields,
            endTime: endTimeFields,
            employee: data.employee,
            grade: data.grade,
            priority: data.priority,
        };

        await axios
            .patch(`${API_URL}/${API_VERSION}/availability/${props.id}`, formattedData, {
                headers: {
                    accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((response) => {
                setShowAlert(true);
                setType("Success");
                setMessage("Employee availability updated.");
                setTimeout(() => {
                    props.onHide();
                    reset();
                }, 1500);
            })
            .catch((error) => {
                setMessage("Something went wrong.");
                setShowAlert(true);
                setType("Danger");
            });
    };


    const handleClose = () => {
        reset();
        props.onHide();
    };

    useEffect(() => {
        fetchUsers();
        if (props.id) {
            fetchUserDetails();
        }
    }, [props.id]); // Fetch user details when userId changes

    const handleDateChange = (date) => {
     //setSelectedDate(date);
     setSelectedDate(format(date, "dd/MM/yyyy"));
        setValue('date', date);
        setShowDatePicker(false);
    };

    const addStartTimeField = () => {
        setStartTimeFields([...startTimeFields, ""]);
    };

    const removeStartTimeField = (index) => {
        const newStartTimes = startTimeFields.filter((_, i) => i !== index);
        setStartTimeFields(newStartTimes);
    };

    const addEndTimeField = () => {
        setEndTimeFields([...endTimeFields, ""]);
    };

    const removeEndTimeField = (index) => {
        const newEndTimes = endTimeFields.filter((_, i) => i !== index);
        setEndTimeFields(newEndTimes);
    };

    return (
        <>
            <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
                onHide={handleClose}
            >
                <form onSubmit={handleSubmit(updateUser)}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Edit Availability
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <div className="form-group mb-3">
                            <label>Date</label>
                            <input
                                className={`form-control ${errors.date ? "is-invalid" : ""}`}
                                type="text"
                                style={{ width: "120px" }}
                                value={selectedDate}
                                placeholder="dd/MM/yyyy"
                                onChange={(e) => setDateError(false)}
                                onClick={() => setShowDatePicker(true)}
                            //readOnly
                            />

                            {dateError && (
                                <div className="text-danger">Please select a date</div>
                            )}
                            {showDatePicker && (
                                <DatePicker
                                    minDate={new Date()}
                                    onChange={handleDateChange}
                                    dropdownMode="select"
                                />
                            )}
                        </div> */}
                             <div className="form-group mb-3">
                            <label>Date</label><br />
                            <DatePicker
                               // selected={selectedDate}
                                onChange={handleDateChange}
                                minDate={new Date()}
                                dateFormat="dd/MM/yyyy"
                                value={selectedDate}
                                className={`form-control `}
                                placeholderText="Select a date"
                                customInput={
                                    <input
                                        style={{ width: "100%" }}
                                        readOnly
                                    />
                                }
                            />
                         {dateError ? <div className="text-danger">Please select a date.</div>:""}
                        </div>
                        <div className="form-group mb-3">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <label>Start Time</label>
        </div>
        {startTimeFields.map((startTime, index) => (
          <div className="d-flex mb-2" key={index}>
            <div className="timepicker">
              <TimePicker
                value={startTime ? moment(startTime, 'HH:mm') : null} // Convert string to moment object
                showSecond={false}
                onChange={(time) => {
                  const newStartTimes = [...startTimeFields];
                  newStartTimes[index] = time ? time.format('HH:mm') : ''; // Update with formatted time string
                  setStartTimeFields(newStartTimes);
                }}
                className={`form-control ${errors.startTime ? "is-invalid" : ""}`}
              />
            </div>
            <button
              type="button"
              className="btn btn-danger ms-2"
              onClick={() => removeStartTimeField(index)}
            >
              -
            </button>
          </div>
        ))}
        {startTimeError && (
          <div className="text-danger">Start time is required</div>
        )}
        <div className="d-flex align-items-center mb-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={addStartTimeField}
          >
            + Add more
          </button>
        </div>
      </div>

      {/* End Time Fields */}
      <div className="form-group mb-3">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <label>End Time</label>
        </div>
        {endTimeFields.map((endTime, index) => (
          <div className="d-flex mb-2" key={index}>
            <div className="timepicker">
              <TimePicker
                value={endTime ? moment(endTime, 'HH:mm') : null} // Convert string to moment object
                showSecond={false}
                onChange={(time) => {
                  const newEndTimes = [...endTimeFields];
                  newEndTimes[index] = time ? time.format('HH:mm') : ''; // Update with formatted time string
                  setEndTimeFields(newEndTimes);
                }}
                className={`form-control ${errors.endTime ? "is-invalid" : ""}`}
              />
            </div>
            <button
              type="button"
              className="btn btn-danger ms-2"
              onClick={() => removeEndTimeField(index)}
            >
              -
            </button>
          </div>
        ))}
        {endTimeError && (
          <div className="text-danger">End time is required</div>
        )}
        <div className="d-flex align-items-center mb-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={addEndTimeField}
          >
            + Add more
          </button>
        </div>
      </div>

                        {/* Employee Select Field */}
                        <div className="form-group mb-3">
                            <label>Employee</label>
                            
                            <select
                                className={`form-control ${errors.employee ? "is-invalid" : ""}`}
                                name="employee"
                                value={selectedEmployee}
                                {...register("employee")}
                                onChange={(e) => {
                                    const selectedId = e.target.value;
                                    setSelectedEmployee(selectedId); // Update local state
                                    setValue("employee", selectedId); // Update react-hook-form value
                                }}
                               // size={10}
                            >
                                <option value="">Select an employee</option>
                                {userList.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.firstName} {user.lastName}
                                    </option>
                                ))}
                            </select>
                            </div>
                            {employeeError && (
                                <div className="text-danger">Employee is required</div>
                            )}
                      

                        {/* Grade Field */}
                        <div className="form-group mb-3">
                            <label>Request Grade</label>
                            <select
                                className={`form-control ${errors.grade ? "is-invalid" : ""}`}
                                name="grade"
                                {...register("grade")}
                            >
                                <option value="">Select a grade</option>
                                <option value="Band 3 MHSW">Band 3 MHSW</option>
                               
                                <option value="Band 5 RN">Band 5 RN</option>
                                <option value="Band 5 RMN">Band 5 RMN</option>
                            </select>
                           {gradeErr && <div className="text-danger">Request grade is required</div>}
                        </div>

                        {/* Priority Field */}
                        <div className="form-group mb-3">
                            <label>Priority</label>
                            <input
                                className={`form-control ${errors.priority ? "is-invalid" : ""}`}
                                type="number"
                                placeholder="Priority"
                                {...register("priority")}
                            />
                           {priorityError && <div className="text-danger">Priority is required</div>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={handleClose}>
                            Close
                        </button>
                        <button className="btn btn-primary" type="submit">
                            Save Changes
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default EditUser;
