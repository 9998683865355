import React, { useEffect, useState } from "react";

import admin_logo  from "../../Assets/images/admin_logo.png";
const Header = ({ onToggleSidebar }) => {
  const name = localStorage.getItem("NAME");
  const [loginUserNAme, setLoginUserNAme] = useState(name);
  useEffect(() => {
    setLoginUserNAme(localStorage.getItem("NAME"));
  }, [name]);


  return (
    <>
      <header className="dashboard-app">
        <div className="dashboard-toolbar">
          <div className="head_wrap">
            <a
              href="javascript:void(0);"
              className="menu-toggle"
              id="toggleSidebarBtn"
              onClick={onToggleSidebar}
            >
              <i className="fas fa-bars"></i>
            </a>
            <a className="logo-mob d-lg-none d-flex" href="/">
              <img src={admin_logo} alt="" />
            </a>
            <div className="head_right">
              <a href="" className="user_info">
                <i className="fa-solid fa-user-large"></i>
                {loginUserNAme}
              </a>
            
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
