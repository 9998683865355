import React, { useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import axios from "axios";
import ReactTost from "../ReactTost";
const ChangePassword = () => {
    const [password, setOldPassword] = useState();
    const [new_password, setNewPassword] = useState();
    const [confirm_password, setConfirmPassword] = useState();
    const [newPasswordError, setNewPasswordError] = useState("");
    const [PasswordError, setPasswordError] = useState("")
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [fieldErrors, setFieldErrors] = useState({
        name: "",
        phone: "",
        email: "",
        company: "",
        website: "",
        password: "",
        confirmPassword: "",
        condition: "",
    });
    const [type, setType] = useState("");
    const [message, setMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;
    const API_VERSION = process.env.REACT_APP_API_VERSION;

    function isPasswordStrong(password) {
        const minLength = 8;
        const maxLength = 20;
        const hasUpperCase = /[A-Z]/.test(new_password);
        const hasLowerCase = /[a-z]/.test(new_password);
        const hasNumbers = /\d/.test(new_password);
        const hasSpecialChars = /[!@#$%^&*]/.test(new_password);

        return (
            new_password &&
            new_password.length >= minLength &&
            new_password.length <= maxLength &&
            hasUpperCase &&
            hasLowerCase &&
            hasNumbers &&
            hasSpecialChars
        );
    }

    function ChangePassword(e) {
        e.preventDefault();
        setNewPasswordError("");
        setConfirmPasswordError("");

        if (!new_password || !confirm_password || !password)  {
            setNewPasswordError("Password cannot be empty");
            setConfirmPasswordError("Password cannot be empty");
            setPasswordError("Password cannot be empty");
            return false;
        }

        if (new_password !== confirm_password) {
            setNewPasswordError("Password and confirm password must match");
            setConfirmPasswordError("Password and confirm password must match");
            return false;
        }

        if (!isPasswordStrong(new_password)) {
            setNewPasswordError(
                "Your password must be 8-20 characters long, contain letters, numbers and special characters"
            );
            return false;
        }

        if (
            password &&
            confirm_password &&
            new_password &&
            new_password === confirm_password
        ) {
            setLoading(true);
            axios
                .post(
                    `${API_URL}/${API_VERSION}/users/change-password`,
                    {
                        password,
                        new_password,
                        confirm_password,
                    },
                    {
                        headers: {
                            accept: "application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then((response) => {
                    setLoading(false);
                    setType("Success");
                    setMessage("Password is changed.");
                    setShowAlert(true);
                })
                .catch((error) => {
                    setLoading(false);
                    setType("Danger");
                    setMessage("Invalid current password");

                    setShowAlert(true);
                });
        }
    }

    function handleInputChange(field) {
        setFieldErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    }

    return (
      
        <div>
              <ReactTost
        onHide={() => {
            setShowAlert(false);
            setType("");
            setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
    />
            <Row className="d-flex justify-content-center align-items-center"
            >
                <Col md={6}>
                    <Card>
                        <Card.Body className="p-4">
                            <h4>Change password</h4>
                            <Row>
                                <Col md={12}>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Old password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="oldPassword"
                                            name="oldPassword"
                                            onChange={(event) => setOldPassword(event.target.value)}
                                        />
                                    </div>
                                    {PasswordError && (
                                            <small className="text-danger">{PasswordError}</small>
                                        )}
                                </Col>

                                <Col md={12}>
                                    <div className="form-group mb-3">
                                        <label className="form-label">New password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="newPassword"
                                            name="newPassword"
                                            onChange={(event) => {
                                                setNewPassword(event.target.value);
                                                handleInputChange("new_password");
                                            }}
                                        />
                                        {newPasswordError && (
                                            <small className="text-danger">{newPasswordError}</small>
                                        )}
                                    </div>
                                </Col>

                                <Col md={12}>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Confirm password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            onChange={(event) =>
                                                setConfirmPassword(event.target.value)
                                            }
                                        />
                                        {confirmPasswordError && (
                                            <small className="text-danger">
                                                {confirmPasswordError}
                                            </small>
                                        )}
                                    </div>
                                </Col>
                                <Col md={12} className="text-end">
                                    <button
                                        className="button"
                                        onClick={(e) => ChangePassword(e)}
                                    >
                                        Change
                                    </button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default ChangePassword;
