import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../../redux/adminUser";
import logo from "../../Assets/images/admin_logo.png";

const Sidebar = ({ onToggleSidebar }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Function to close the sidebar and navigate to the link
  const closeSidebarAndNavigate = (link) => {
    if (isMobile) {
      onToggleSidebar();
    }
    navigate(link); // Navigate to the link
  };

  function Logout() {
    dispatch(logout());
    localStorage.clear();
    navigate("/");
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let location = useLocation();
  const isActive = (pathname) => location.pathname === pathname;
  const isActiveLink = (pathname) => location.pathname.startsWith(pathname);

  return (
    <>
      <div className="dashboard-nav">
        <header>
          <a href="javascript:void(0);" className="menu-toggle" id="toggleSidebarBtn" onClick={onToggleSidebar}>
            <i className="fas fa-times"></i>
          </a>
          <div className="logo" style={{color:"white"}}>
           SuperBot
          </div>
        </header>
        <nav className="dashboard-nav-list">
          <Nav className="dashboard-nav-list">
            <Link
              className={`dashboard-nav-item ${
                (isActive("/employee-list") || isActiveLink("/employee-list")) ? "admin-active-link" : ""
              }`}
              onClick={() => closeSidebarAndNavigate("/employee-list")}
              to="employee-list"
            >
              <i className="fa-solid fa-user-group"></i> Employees
            </Link>
            <Link
              className={`dashboard-nav-item ${
                (isActive("/employee-availability") || isActiveLink("/employee-availability")) ? "admin-active-link" : ""
              }`}
              onClick={() => closeSidebarAndNavigate("/employee-availability")}
              to="employee-availability"
            >
              <i className="fa-solid fa-clock"></i> Availability management
            </Link>
            <Link
              className={`dashboard-nav-item ${
                (isActive("/booking-logs") || isActiveLink("/booking-logs")) ? "admin-active-link" : ""
              }`}
              onClick={() => closeSidebarAndNavigate("/booking-logs")}
              to="booking-logs"
            >
              <i className="fa-solid fa-book"></i> Booking logs
            </Link>
            <Link
              className={`dashboard-nav-item ${
                (isActive("/change-password") || isActiveLink("/change-password")) ? "admin-active-link" : ""
              }`}
              onClick={() => closeSidebarAndNavigate("/change-password")}
              to="change-password"
            >
              <i className="fa-solid fa-key"></i> Change password
            </Link>
            <div className="nav-item-divider" />
            <a className="dashboard-nav-item" onClick={() => Logout()}>
              <i className="fas fa-sign-out-alt" />
              Logout
            </a>
          </Nav>
        </nav>
      </div>
    </>
  );
};
export default Sidebar;
