import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import axios from "axios";
import ReactTost from "../../ReactTost";

const EditUser = (props) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const editUser = async (data) => {
    await axios
      .patch(
        `${API_URL}/${API_VERSION}/employees/${props.id}`,
        {
          firstName: data.firstName,
          lastName: data.lastName,
        
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setShowAlert(true);
        setType("Success");
        setMessage("Employee updated.");
        setTimeout(() => {
          props.onHide();
          reset();
        }, 1500);
      })
      .catch((error) => {
        setShowAlert(true);
        setType("Danger");
        console.error("Error sending data:", error);
        setMessage("Employee not updated.");
      });
  };

  useEffect(() => {
    if (props.id) {
      axios
        .get(`${API_URL}/${API_VERSION}/employees/${props.id}`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
        //  console.log(response.data);
          
          setValue("firstName", response.data.firstName);
          setValue("lastName", response.data.lastName);
        
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [API_URL, API_VERSION, props.id]);

  const handleClose = () => {
    reset();
    props.onHide();
  };

  return (
    <>
      <ReactTost
        onHide={() => {
          setType("");
          setMessage("");
          setShowAlert(false);
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        onHide={handleClose}
      >
        <form onSubmit={handleSubmit(editUser)}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit employee{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group mb-3">
              <label className="">First name</label>
              <input
                className={`form-control ${
                  errors.firstName ? "is-invalid" : ""
                }`}
                type="text"
                name="firstName"
                id="firstName"
                placeholder=""
                {...register("firstName", {
                  required: "First name is required.",
                })}
              />
              {errors.firstName && (
                <div className="invalid-feedback">
                  {errors.firstName.message}
                </div>
              )}
            </div>
            <div className="form-group mb-3">
              <label className="">Last name</label>
              <input
                className={`form-control ${
                  errors.lastName ? "is-invalid" : ""
                }`}
                type="text"
                name="lastName"
                id="lastName"
                placeholder=""
                {...register("lastName", {
                  required: "Last name is required.",
                })}
              />
              {errors.lastName && (
                <div className="invalid-feedback">
                  {errors.lastName.message}
                </div>
              )}
            </div>
         
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="button">
              SUBMIT
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default EditUser;
