import '@fortawesome/fontawesome-free/css/all.min.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './responsive.css';
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Sidebar from "../src/Component/Admin/Layout/Sidebar";
import Header from "./Component/Admin/Layout/Header";
import Login from "./Component/Admin/Login";
import UserList from './Component/Admin/User/UserList';
import EmployeeAvailability from './Component/Admin/EmployeeAvailability/EmployeeAvailability';

import ChangePassword from './Component/Admin/ChangePassword';
import BookingLogs from './Component/Admin/BookingLogs/BookingLogs';

function App() {
  const [isSidebarActive, setSidebarActive] = useState(false);
  const toggleSidebar = () => {
    setSidebarActive(!isSidebarActive);
  };
 
  const isLoggedIn = useSelector((state) => state.user.userData);
  const isAdmin = useSelector((state) => state.user.IsUserLevel);

  if (isAdmin === 'admin') {
    return (
      <>
        <div className={`dashboard-main ${isSidebarActive ? 'dashboard-compact' : ''}`}> 
          <Router>
            {isLoggedIn && <Sidebar onToggleSidebar={toggleSidebar} />}
            {isLoggedIn && <Header onToggleSidebar={toggleSidebar} />}
            <div className='dashboard-content'>
              <Routes>
                <Route path="/employee-list" element={isAdmin === "admin" ? (<UserList /> ): (<Navigate to="/" />) } /> 
             
                <Route path="/employee-availability" element={isAdmin === "admin" ? (<EmployeeAvailability /> ): (<Navigate to="/" />) } /> 
                <Route path="/change-password" element={isAdmin === "admin" ? (<ChangePassword /> ): (<Navigate to="/" />) } /> 
                <Route path="/booking-logs" element={isAdmin === "admin" ? (<BookingLogs /> ): (<Navigate to="/" />) } /> 

              </Routes>
            </div>
         
          </Router>
        </div>
      </>
    );
  } 
  else {
    return (
      <>
        <Router>         
          <Routes>
            <Route path="/" element={<Login />} />
          
          </Routes>    
        </Router>
      </>
    );
  }
}

export default App