import React, { useEffect, useState } from "react";
import { ToastContainer, Toast } from "react-bootstrap";


function ReactTost(props) {
  const [alertClass, setAlertClass] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    setAlertClass(props.alertClass);
    setAlertMessage(props.alertMessage);
    setShow(true);
  }, [props]);

  return (
    <>
      {alertClass && alertMessage && show && (
        <ToastContainer
          className="p-3"
          position="top-end"
          style={{ zIndex: 1 }}
        >
          <Toast
            className="d-inline-block m-1"
            bg={alertClass.toLowerCase()}
            delay={3000}
            onClose={() => {
              props.onHide();
              setShow(false);
            }}
            show={show}
            autohide
          >
            <Toast.Body className="text-white">{alertMessage}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
    </>
  );
}

export default ReactTost;
