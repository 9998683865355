import axios from "axios";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { login } from "../../redux/adminUser";
import ReactTost from "../ReactTost";


const Login = () => {
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = "admin";
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const {t} = useTranslation();

  const {handleSubmit,register,formState: { errors },} = useForm();

 

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const isLoggedIn = useSelector((state) => state.user.userData);
  const goToLogin = async (data) => {
    // e.preventDefault();  
    if (data.email && data.password) {
      // Perform login logic here
      return await axios
        .post(`${API_URL}/${API_VERSION}/auth/login`, {
          email:data.email,
          password:data.password,
        })
        .then((response) => {
          //console.log(response.data)
          if (response.data.user.role === "admin") {
            localStorage.setItem("isLogin", true);
            localStorage.setItem("id", response.data.user.id);
            localStorage.setItem("isRole", response.data.user.role);
            localStorage.setItem("EMAIL", response.data.user.email);
            localStorage.setItem("NAME", response.data.user.name);
            localStorage.setItem("token", response.data.tokens.access.token);
            dispatch(
              login({
                userData: true,
                IsUserLevel: response.data.user.role
              })
            );
            navigate("/employee-availability");;
          } else {
            setType("Danger");
            setMessage(t("You are not allowed."));
            setShowAlert(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setType("Danger");
          setMessage(t(error.response.data.message));
          setShowAlert(true);
        });
    }
  };

  return !isLoggedIn ? (
    <section className="login_main">
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      
      <div className="login_form">
        <Container>
          <Row className="justify-content-center">
            <Col xxl={5} xl={4} lg={6} md={8} sm={10}>
             
              <div className="boxwrap">
                <h1>Login</h1>
                <p className="mb-3">Sign in to your account to continue</p>
                <form onSubmit={handleSubmit(goToLogin)}>
                  <Col md={12}>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <i className="fa-solid fa-envelope"></i>
                      </span>
                      <input
                        type="text"
                        placeholder="Email"
                        name="email"
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        onChange={onChange}
                        {...register("email", {
                          required: 'Email is required.',
                        })}
                      />
                      {errors.email && (
                        <div className="invalid-feedback px-1 bg-light">
                          {errors.email.message}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <i className="fa-solid fa-lock"></i>
                      </span>
                      <input
                        type="password"
                        className={`form-control ${
                          errors.password ? "is-invalid" : ""
                        }`}
                        placeholder="Password"
                        name="password"
                        onChange={onChange}
                        {...register("password", {
                          required: 'Password is required.',
                        })}
                      />
                      {errors.password && (
                        <div className="invalid-feedback px-1 bg-light">
                          {errors.password.message}
                        </div>
                      )}
                    </div>
                  </Col>
                  <div className="d-flex align-items-center flex-lg-row flex-column">
                    <div className="text-start mb-4 order-lg-0 order-1">
                      <input
                        type="submit"
                        value="Sign in"
                        className="button_dark"
                        // onClick={goToLogin}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  ) : (
    <Navigate to="/employee-availability" />
  );
};

export default Login;
